
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, { renderImageUrlEditor } from "@/core/services/AppService";
import { Actions } from "@/store/enums/StoreEnums";
import { CategoryDefault, categoryModel } from "@/store/model/Category";
import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";

export default defineComponent({
  name: "edit-account",
  props: ["id"],
  components: { editor: Editor },
  setup(props) {
    const image_editor = ref();
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataNewsCategory = ref([]);
    let objectDetail = ref<categoryModel>(
      JSON.parse(JSON.stringify(CategoryDefault))
    );
    let x =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth;
    let y =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;
    const editorConfig = {
      image_advtab: true,
      image_caption: true,
      image_description: true,
      image_uploadtab: true,
      height: 500,
      menubar: true,
      file_picker_callback: function (callback, value, meta) {
        let url = "/manager-media?type=editor";
        const instanceApi = getTinymce().activeEditor.windowManager.openUrl({
          url: url,
          title: "Quản lý media",
          width: x * 0.8,
          height: y * 0.95,
          onMessage: (api, message) => {
            //console.log("message", message, api);
          },
          onClose: async (instance, trigger) => {
            let dataImage = JSON.parse(image_editor.value);
            //console.log(dataImage);
            let image = renderImageUrlEditor(dataImage);
            let width = dataImage.width ? dataImage.width : "800";
            if (width > 1200) width = 1000;
            callback(image, {
              text: dataImage["path"],
              alt: dataImage["originalname"],
              width: width.toString(),
              height: "auto",
            });
          },
          onAction: function (instance, trigger) {
            // code for when "custom" buttons are triggered
            //console.log("instance", instance, trigger);
          },
        });
      },
      plugins: [
        "advlist autolink lists link charmap",
        "searchreplace visualblocks code fullscreen",
        "print preview anchor insertdatetime media image",
        "paste code  wordcount table advtable",
      ],
      toolbar:
        "insertfile  |formatselect | bold italic strikethrough underline |backcolor  color | subscript superscript |   alignleft aligncenter alignright alignjustify |  outdent  indent  anchor undo redo | lists   bullist numlist outdent indent | table |image|code|fullscreen",
    };
    onMounted(async () => {
      await store.dispatch(Actions.GET_NEWS_CATEGORY_DETAIL, props.id);
      await store.dispatch(
        Actions.GET_ALL_NEWS_CATEGORY,
        '?search={"type":"news"}'
      );
      listDataNewsCategory.value = store.getters["listDataCategory"];
      objectDetail.value = await store.getters["getCurrentCategory"];
      setCurrentPageBreadcrumbs("Cập nhật ", ["Danh mục"]);
      MenuComponent.reinitialization();
    });
    const rules = ref({
      name: [
        {
          required: true,
          message: "Tiêu đề không được để trống",
          trigger: "blur",
        },
      ],
    });
    const convertNameToSlug = () => {
      objectDetail.value["slug"] = AppService.convertSlug(
        objectDetail.value["name"]
      );
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          objectDetail.value["active"] =
            objectDetail.value["active"] == "active" ||
            objectDetail.value["active"]
              ? "active"
              : "inactive";
          store
            .dispatch("UPDATE_NEWS_CATEGORY", objectDetail.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông tin thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                // Go to page after successfully login
                if (result.isConfirmed) {
                  router.push({ name: "news" });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    return {
      rules,
      submit,
      convertNameToSlug,
      loading,
      objectDetail,
      listDataNewsCategory,
      formRef,
      image_editor,
      editorConfig,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
      }
      if (event.data.action == "image-feature") {
        this.objectDetail["image"] = JSON.parse(event.data.image);
      }
    },
  },
});
